import React, { useContext, useState } from 'react';

import { styled } from 'styles';
import Accordion from 'components/Accordion';
import { Button, Dropdown, Modal } from 'components';
import notify from './editorNotify';
import PDFEditorContext from '../context';

interface SidebarProps {
  openShareAndSign: () => void;
  openSaveAs: () => void;
}

const signatureOptions = [
  {
    value: 'seller_attorney',
    text: 'Seller Attorney Signature'
  },
  {
    value: 'seller_broker',
    text: 'Seller Broker Signature'
  },
  {
    value: 'seller',
    text: 'Seller Signature'
  }
];

const Sidebar: React.FC<SidebarProps> = ({ openShareAndSign, openSaveAs }) => {
  const { editor, editorDispatch } = useContext(PDFEditorContext);

  return (
    <StyledSidebar className="editor-sidebar">
      <Accordion label="Edit">
        <Button
          className="editor-sidebar__item"
          simple
          onClick={() => {
            editorDispatch({
              type: 'SET_IS_ADDING_NODE',
              value: { isAddingNode: true, newNodeType: 'field' }
            });
            notify(
              'Click anywhere on the document, and add an Input Box which can be autofilled with Project Data'
            );
          }}>
          Add Field
        </Button>
        <Button
          className="editor-sidebar__item"
          simple
          onClick={() => {
            editorDispatch({
              type: 'SET_IS_ADDING_NODE',
              value: { isAddingNode: true, newNodeType: 'text' }
            });
            notify('Click anywhere on the document, and add one sentence of text');
          }}>
          Add Text
        </Button>
        <Button
          className="editor-sidebar__item"
          simple
          onClick={() => {
            notify('Click on any of the added elements to delete it');
            editorDispatch({
              type: 'SET_IS_DELETING_NODE',
              value: true
            });
          }}>
          Delete Field
        </Button>
        <Button
          className="editor-sidebar__item"
          simple
          onClick={() => {
            editorDispatch({
              type: 'SET_IS_ADDING_NODE',
              value: { isAddingNode: true, newNodeType: 'comment' }
            });
            notify('Click anywhere on the document, and add a comment to the document');
          }}>
          Add Comment
        </Button>
        <Button
          className="editor-sidebar__item"
          simple
          onClick={() => {
            editorDispatch({
              type: 'SET_IS_ADDING_NODE',
              value: { isAddingNode: true, newNodeType: 'strikethrough' }
            });
            notify(
              'Click anywhere on the document, hold your mouse and drag to create a strikethrough line'
            );
          }}>
          Strikethrough
        </Button>
        <Button className="editor-sidebar__item" simple onClick={() => null}>
          Auto Add Fields
        </Button>
      </Accordion>
      <Accordion label="E-Signature">
        <StyledDropdown
          options={signatureOptions}
          value={null}
          onChange={() => {
            notify('Click anywhere on the document to add signature field');
            editorDispatch({
              type: 'SET_IS_ADDING_NODE',
              value: { isAddingNode: true, newNodeType: 'signature' }
            });
          }}
          label="Add Signature Field"
          buttonLike
          className="editor-sidebar__item"
        />
        <StyledDropdown
          options={signatureOptions}
          value={null}
          onChange={() => {
            notify('Click anywhere on the document to add initials');
            editorDispatch({
              type: 'SET_IS_ADDING_NODE',
              value: { isAddingNode: true, newNodeType: 'initials' }
            });
          }}
          label="Add Initials Field"
          buttonLike
          className="editor-sidebar__item"
        />
        <Button
          className="editor-sidebar__item"
          simple
          onClick={() => {
            editorDispatch({
              type: 'SET_IS_ADDING_NODE',
              value: { isAddingNode: true, newNodeType: 'signature' }
            });
            notify('Click anywhere on the document to add your signature');
          }}>
          Sign
        </Button>
      </Accordion>
      <Accordion label="Store">
        <Button className="editor-sidebar__item" simple onClick={() => null}>
          Save
        </Button>
        <Button className="editor-sidebar__item" simple onClick={openSaveAs}>
          Save As
        </Button>
      </Accordion>
      <Accordion label="Send">
        <Button className="editor-sidebar__item" simple onClick={openShareAndSign}>
          Share
        </Button>
        <Button className="editor-sidebar__item" simple onClick={openShareAndSign}>
          Request Signatures
        </Button>
      </Accordion>
      <Accordion label="Project Data">
        <Button
          className="editor-sidebar__item"
          simple
          onClick={() =>
            notify(`Please highlight text with Mouse, then Right Click and press 'Capture Text'`)
          }>
          Capture Text
        </Button>
        <Button className="editor-sidebar__item" simple onClick={() => null}>
          Autofill
        </Button>
      </Accordion>
    </StyledSidebar>
  );
};

export default React.memo(Sidebar);

const StyledSidebar = styled.div`
  .editor-sidebar {
    &__item {
      width: 100%;
      display: block;
      padding: 5px 0;
      text-align: left;
      &.active {
        color: ${props => props.theme.colors.red};
      }
      &:focus {
        opacity: 1;
        color: ${props => props.theme.colors.red};
      }
    }
  }
`;

const StyledDropdown = styled(Dropdown)`
  .dropdown-button {
    border: none;
    padding: 0;
    height: auto;
    label {
      font-weight: 600;
      color: #000000;
      cursor: pointer;
    }
  }
  &.open {
    .dropdown-button {
      label {
        color: ${props => props.theme.colors.red};
      }
    }
  }
  &.button-like {
    display: block;
  }
  .icon {
    display: none;
  }
  .dropdown-options {
    width: auto;
  }
`;
