import React from 'react';
import { useForm, Controller } from 'react-hook-form';

import { styled } from 'styles';
import { Button, Checkbox, Dropdown, Input, InputCurrency, InputDate } from 'components';
import { documentTypeOptions } from 'utils/documents';

const extraFields = {
  listingDocuments: [10, 16],
  contractAndAmendments: [1, 2, 3, 5, 6, 8, 9]
};

const ShareAndSign = () => {
  const { register, control, watch, errors, handleSubmit } = useForm();
  const { draft, doctype } = watch(['draft', 'doctype']);

  const submit = values => {
    console.log(values);
  };

  return (
    <StyledSaveAs className="save-form" onSubmit={handleSubmit(submit)}>
      <Input
        ref={register({ required: 'Required' })}
        name="filename"
        error={errors.filename?.message}
        label="File Name"
        placeholder="Enter File Name"
        className="save-form__input"
      />
      <Controller
        control={control}
        rules={{ required: 'Required' }}
        name="doctype"
        defaultValue=""
        render={controllerProps => (
          <Dropdown
            options={documentTypeOptions}
            label="Document Type"
            placeholder="Select Document Type"
            error={errors.doctype?.message}
            {...controllerProps}
            className="save-form__input"
          />
        )}
      />
      <Checkbox
        ref={register}
        name="draft"
        label="Draft?"
        className="save-form__input"
      />
      {draft === false && (
        <>
          {extraFields.listingDocuments.includes(doctype) && (
            <>
              <Controller
                control={control}
                name="salesCommission"
                defaultValue=""
                render={controllerProps => (
                  <InputCurrency
                    label="Seller Commissions ($)"
                    placeholder="Enter"
                    className="save-form__input"
                    {...controllerProps}
                  />
                )}
              />
              <Controller
                control={control}
                name="purchase_commission"
                defaultValue=""
                render={controllerProps => (
                  <InputCurrency
                    label="Buyer Commissions ($)"
                    placeholder="Enter"
                    className="save-form__input"
                    {...controllerProps}
                  />
                )}
              />
            </>
          )}
          {extraFields.contractAndAmendments.includes(doctype) && (
            <>
              <Controller
                control={control}
                name="purchasePrice"
                defaultValue=""
                render={controllerProps => (
                  <InputCurrency
                    label="Purchase Price"
                    placeholder="Enter"
                    className="save-form__input"
                    {...controllerProps}
                  />
                )}
              />
              <Controller
                control={control}
                name="earnestMoney1"
                defaultValue=""
                render={controllerProps => (
                  <InputCurrency
                    label="Earnest Money (1st inst)"
                    placeholder="Enter"
                    className="save-form__input"
                    {...controllerProps}
                  />
                )}
              />
              <Controller
                control={control}
                name="earnestMoney2"
                defaultValue=""
                render={controllerProps => (
                  <InputCurrency
                    label="Earnest Money (2st inst)"
                    placeholder="Enter"
                    className="save-form__input"
                    {...controllerProps}
                  />
                )}
              />
              <Controller
                control={control}
                name="earnestMoney2"
                defaultValue=""
                render={controllerProps => (
                  <InputDate
                    inputProps={{ label: 'Closing Date', placeholder: 'MM/YY/DD' }}
                    className="save-form__input"
                    {...controllerProps}
                  />
                )}
              />
              <Controller
                control={control}
                name="closingCostCredit"
                defaultValue=""
                render={controllerProps => (
                  <InputCurrency
                    label="Closing Cost Credit"
                    placeholder="Enter"
                    className="save-form__input"
                    {...controllerProps}
                  />
                )}
              />
              <Controller
                control={control}
                name="executionDate"
                defaultValue=""
                render={controllerProps => (
                  <InputDate
                    inputProps={{ label: 'Execution Date', placeholder: 'MM/YY/DD' }}
                    className="save-form__input"
                    {...controllerProps}
                  />
                )}
              />
              <Controller
                control={control}
                name="attorneyModificationDeadline"
                defaultValue=""
                render={controllerProps => (
                  <InputDate
                    inputProps={{
                      label: 'Attorney Modification Deadline',
                      placeholder: 'MM/YY/DD'
                    }}
                    className="save-form__input"
                    {...controllerProps}
                  />
                )}
              />
              <Controller
                control={control}
                name="inspectionDeadline"
                defaultValue=""
                render={controllerProps => (
                  <InputDate
                    inputProps={{ label: 'Inspection Deadline', placeholder: 'MM/YY/DD' }}
                    className="save-form__input"
                    {...controllerProps}
                  />
                )}
              />
              <Controller
                control={control}
                name="mortgageContingencyDeadline"
                defaultValue=""
                render={controllerProps => (
                  <InputDate
                    inputProps={{
                      label: 'Mortgage Contingency Deadline',
                      placeholder: 'MM/YY/DD'
                    }}
                    className="save-form__input"
                    {...controllerProps}
                  />
                )}
              />
              <Controller
                control={control}
                name="extrasTotal"
                defaultValue=""
                render={controllerProps => (
                  <InputCurrency label="Extras (Total)" placeholder="0.00" className="save-form__input" {...controllerProps} />
                )}
              />
            </>
          )}
        </>
      )}
      <Button onClick={() => null} className="save-form__btn">Store Document</Button>
    </StyledSaveAs>
  );
};

export default React.memo(ShareAndSign);

const StyledSaveAs = styled.form`
  .save-form {
    display: flex;
    flex-direction: column;
    &__input {
      display: flex;
      width: 100%;
      max-width: 300px;
      margin: 0 0 16px;
      &.dropdown {
        flex-direction: column;
      }
    }
    &__btn {
      width: 100%;
      max-width: 300px;
      display: block;
    }
  }
`;