import React from 'react';
import toaster from 'toasted-notes';
import { styled } from 'styles';
import { MessageOptionalOptions } from 'toasted-notes/lib/ToastManager';
import { Icon } from 'components';

export default function editorNotify(content: React.ReactNode, settings?: MessageOptionalOptions) {
  toaster.notify(
    props => (
      <StyledNotification {...props}>
        {content} <Icon name="mini-x" onClick={props.onClose} width={10} height={10} />
      </StyledNotification>
    ),
    {
      duration: 5000,
      position: 'bottom-right',
      ...settings
    }
  );
}

const StyledNotification = styled.div`
  display: flex;
  align-items: center;
  margin: 0 25px 10px 0;
  padding: 5px;
  background-color: #df1721;
  color: #ffffff;
  font-size: 14px;
  text-align: left;
  white-space: nowrap;
  .icon {
    cursor: pointer;
    margin: 0 0 0 5px;
    fill: #ffffff;
  }
`;
