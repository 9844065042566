import { EditableNode, EditableNodeType } from './types';

export type EditorReducerAction =
  | {
      type: 'SET_IS_ADDING_NODE';
      value: { isAddingNode: boolean; newNodeType: EditableNodeType | undefined };
    }
  | {
      type: 'SET_IS_DELETING_NODE';
      value: boolean;
    }
  | {
      type: 'ADD_NODE';
      value: EditableNode;
    }
  | {
      type: 'EDIT_NODE';
      value: EditableNode;
    }
  | {
      type: 'DELETE_NODE';
      value: number;
    };

export type EditorReducerState = {
  isAddingNode: boolean;
  isDeletingNode: boolean;
  newNodeType?: EditableNodeType;
  editableNodes: EditableNode[];
};

export const editorReducer = (
  state: EditorReducerState,
  action: EditorReducerAction
): EditorReducerState => {
  switch (action.type) {
    case 'SET_IS_ADDING_NODE':
      return { ...state, ...action.value };
    case 'SET_IS_DELETING_NODE':
      return { ...state, isDeletingNode: action.value };
    case 'ADD_NODE':
      return { ...state, editableNodes: [...state.editableNodes, action.value] };
    case 'EDIT_NODE':
      return {
        ...state,
        editableNodes: state.editableNodes.map(item =>
          item.id === action.value.id ? action.value : item
        )
      };
    case 'DELETE_NODE':
      return {
        ...state,
        editableNodes: state.editableNodes.filter(item => item.id !== action.value)
      };
    default:
      return state;
  }
};

export default editorReducer;
