import React, { useContext, useRef } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import clsx from 'clsx';
import { styled } from 'styles';
import testpdf from './testpdf2.pdf';
import PDFEditorContext from '../context';
import EditableNodeComponent from './EditableNodeComponent';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface PDFViewerProps {
  test?: any;
}

const PDFViewer: React.FC<PDFViewerProps> = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { editor, editorDispatch, currentPage, setTotalPages } = useContext(PDFEditorContext);

  const handleDocumentWrapperClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!editor.isAddingNode) return;
    e.stopPropagation();
    const wrapper = wrapperRef.current;
    const x = e.pageX - wrapper!.offsetLeft;
    const y = e.pageY - wrapper!.offsetTop;
    const position = { x, y };
    editorDispatch({
      type: 'ADD_NODE',
      value: {
        id: Date.now(),
        type: editor.newNodeType!,
        position,
        page: currentPage,
        text: ''
      }
    });
    editorDispatch({
      type: 'SET_IS_ADDING_NODE',
      value: { isAddingNode: false, newNodeType: undefined }
    });
  };

  return (
    <StyledPDFViewer
      ref={wrapperRef}
      className={clsx('pdf-viewer', { 'is-adding-stuff': editor.isAddingNode })}
      onClick={handleDocumentWrapperClick}>
      {editor.editableNodes
        .filter(node => node.page === currentPage)
        .map(node => (
          <EditableNodeComponent
            key={node.id}
            data={node}
            isDeleting={editor.isDeletingNode}
            onChange={node => editorDispatch({ type: 'EDIT_NODE', value: node })}
            onDelete={node => {
              editorDispatch({ type: 'DELETE_NODE', value: node.id });
              editorDispatch({ type: 'SET_IS_DELETING_NODE', value: false });
            }}
          />
        ))}
      <Document file={testpdf} onLoadSuccess={pdf => setTotalPages(pdf.numPages)}>
        <Page pageNumber={currentPage} width={820} />
      </Document>
    </StyledPDFViewer>
  );
};

export default PDFViewer;

const StyledPDFViewer = styled.div`
  border: 1px solid #00000050;
  position: relative;

  &.is-adding-stuff {
    cursor: crosshair;
  }
`;
