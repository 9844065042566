/* eslint-disable jsx-a11y/control-has-associated-label */
import clsx from 'clsx';
import { Dropdown } from 'components';
import useOutsideClick from 'hooks/useOutsideClick';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Draggable, { DraggableData } from 'react-draggable';
import { styled } from 'styles';
import { EditableNode } from '../types';

interface EditableNodeComponentProps {
  data: EditableNode;
  isDeleting?: boolean;
  onChange?: (node: EditableNode) => void;
  onDelete?: (node: EditableNode) => void;
}

const EditableNodeComponent: React.FC<EditableNodeComponentProps> = ({
  data,
  isDeleting,
  onChange,
  onDelete
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLDivElement>(null);

  const handleDragStop = (draggableData: DraggableData) => {
    const position = { x: draggableData.x, y: draggableData.y };
    onChange?.({ ...data, position });
  };

  const handleDraggableInputChange = useCallback(
    (e: React.FormEvent<HTMLDivElement>) => {
      const text = e.currentTarget.innerText;
      onChange?.({ ...data, text });
    },
    [data, onChange]
  );

  const onClick = useCallback(() => {
    setIsOpen(true);
  }, []);

  const content = useMemo(() => {
    switch (data.type) {
      case 'field':
        return (
          <>
            <div className="handle input-handle" />
            <div
              ref={inputRef}
              className="input"
              role="textbox"
              contentEditable
              defaultValue={data.text}
              onInput={e => handleDraggableInputChange(e)}
              onFocus={onClick}
            />
            {isOpen && <Dropdown placeholder="Variable" />}
          </>
        );

      case 'text':
      case 'comment':
      case 'initials':
      case 'signature':
        return (
          <>
            <div className="handle input-handle" />
            <div
              ref={inputRef}
              className="input"
              role="textbox"
              contentEditable
              defaultValue={data.text}
              onInput={e => handleDraggableInputChange(e)}
            />
          </>
        );
      case 'strikethrough':
        return (
          <div className="handle">
            <hr />
          </div>
        );

      default:
        return null;
    }
  }, [data.text, data.type, handleDraggableInputChange, isOpen, onClick]);

  const handleClick = () => {
    if (isDeleting) onDelete?.(data);
  };

  const handleRightClick = e => {
    e.preventDefault();
    onDelete?.(data);
  };

  useOutsideClick(
    wrapperRef.current,
    () => {
      setIsOpen(false);
    },
    { condition: isOpen }
  );

  useEffect(() => {
    if (['field', 'text', 'comment'].includes(data.type)) {
      inputRef.current?.focus();
    }
  }, [data.type]);

  useEffect(() => {
    if (inputRef.current && ['field', 'text', 'comment'].includes(data.type)) {
      inputRef.current.innerText = data.text;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Draggable
      key={data.id}
      defaultPosition={data.position}
      disabled={isDeleting}
      handle=".handle"
      bounds="parent"
      scale={1}
      onStop={(_e, data) => handleDragStop(data)}>
      <StyledNodeComponent
        ref={wrapperRef}
        className={clsx('editable-node', data.type)}
        onClick={handleClick}
        onContextMenu={handleRightClick}>
        {content}
      </StyledNodeComponent>
    </Draggable>
  );
};

export default EditableNodeComponent;

const StyledNodeComponent = styled.div`
  display: inline-flex;
  align-items: center;
  position: absolute;
  z-index: 1000;

  .handle {
    z-index: 1001;
    width: 10px;
    height: 20px;
    cursor: move;

    &.input-handle {
      width: 10px;
      height: 20px;
      background: ${props => props.theme.colors.grayDark};
      opacity: 0.4;
      flex-shrink: 0;
    }
  }

  .input {
    box-sizing: border-box;
    border: 1px dashed ${props => props.theme.colors.seashell};
    min-width: 40px;
    height: 100%;
    outline: none;
    padding: 0 4px;
  }

  .dropdown {
    position: absolute;
    bottom: -45px;
    width: 150px;
  }

  &.strikethrough {
    min-width: 10px;
    align-items: center;
    resize: horizontal;
    border: 1px dashed ${props => props.theme.colors.seashell};
    overflow: auto;

    .handle {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 0 4px;
    }

    hr {
      margin: 0;
      width: 100%;
      border-width: 0 0 2px 0;
      border-color: ${props => props.theme.colors.red};
    }
  }
`;
