import React, { useState } from 'react';
import { styled } from 'styles';
import useSitedataAssets from 'hooks/useSitedataAssets';
import useLayout from 'hooks/useLayout';
import { Button, Icon, SideMenu } from 'components';
import HeaderAvatarMenu from 'components/HeaderAvatarMenu';

interface EditorLayoutProps extends React.HTMLAttributes<HTMLDivElement> {
  content?: React.ReactNode;
  sidebar?: React.ReactNode;
  header?: React.ReactNode;
}

const EditorLayout: React.FC<EditorLayoutProps> = ({ content, sidebar, header, ...props }) => {
  const layout = useLayout();
  const { logo } = useSitedataAssets();
  const [sideMenuIsOpen, setSideMenuIsOpen] = useState(false);

  return (
    <>
      <StyledEditorLayout {...props}>
        <header>
          {layout === 'desktop' && (
            <div className="logo-container">
              {logo && <img src={logo} alt="logo" className="logo" />}
            </div>
          )}
          {layout !== 'desktop' && (
            <Button className="menu-button" simple onClick={() => setSideMenuIsOpen(v => !v)}>
              <Icon name="menu-hamburger" />
            </Button>
          )}
          {header}
          <HeaderAvatarMenu />
        </header>
        <aside className="sidebar">{sidebar}</aside>
        <div className="content">{content}</div>
      </StyledEditorLayout>
      {layout !== 'desktop' && (
        <StyledSideMenu
          hideCloseButton
          position="left"
          open={sideMenuIsOpen}
          onClose={() => setSideMenuIsOpen(false)}>
          {sidebar}
        </StyledSideMenu>
      )}
    </>
  );
};

export default EditorLayout;

const StyledEditorLayout = styled.div`
  display: grid;
  grid-template-areas:
    'area-header area-header'
    'area-sidebar area-content';
  grid-template-rows: auto 1fr;
  grid-template-columns: auto 1fr;
  height: 100vh;

  header {
    grid-area: area-header;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${props => props.theme.colors.seashell};
    height: 80px;
    padding: 0 30px;

    .logo-container {
      width: 253px;
    }

    .avatar-button {
      margin-left: auto;
    }
  }

  aside {
    grid-area: area-sidebar;
    border-right: 1px solid ${props => props.theme.colors.seashell};
    /* padding: 24px 0; */
    width: 253px;
    padding-left: 30px;
  }

  .content {
    grid-area: area-content;
    padding: 24px 30px 24px 0;
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    grid-template-areas:
      'area-header'
      'area-content';
    grid-template-columns: 1fr;

    aside {
      width: 253px;
      position: absolute;
      height: 100%;
      left: -100%;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    header {
      height: 56px;
    }
  }
`;

const StyledSideMenu = styled(SideMenu)`
  background: none;
  animation: none;

  .side-menu {
    box-shadow: 0px 18px 50px rgba(0, 0, 0, 0.16);
  }
`;
