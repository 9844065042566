import React, { useContext, useState } from 'react';
import { styled } from 'styles';
import { Button, Icon, Modal } from 'components';
import EditorLayout from 'components/EditorLayout';
import Sidebar from './components/Sidebar';
import PDFEditorContext, { PDFEditorContextWrapper } from './context';
import PDFViewer from './pdf/PDFViewer';
import SaveAs from './components/SaveAs';
import ShareDocumentModal from './components/ShareDocumentModal';

const PDFEditor = () => {
  const { currentPage, setCurrentPage, totalPages } = useContext(PDFEditorContext);
  const [openSaveAs, setOpenSaveAs] = useState(false);
  const [openShareAndSign, setOpenShareAndSign] = useState(false);

  const content = (
    <StyledContent>
      <PDFViewer />
      <ShareDocumentModal open={openShareAndSign} onClose={() => setOpenShareAndSign(false)} />
      <Modal
        open={openSaveAs}
        modalTitle="Save As"
        onClose={() => setOpenSaveAs(false)}
        className="save-as">
        <SaveAs />
      </Modal>
    </StyledContent>
  );

  const header = (
    <StyledHeader className="editor-header">
      <span className="editor-header__name">
        <Icon name="document-file" /> Document Name.pdf
      </span>
      {totalPages && (
        <div className="document-navigation">
          <Button
            simple
            disabled={currentPage === 1}
            onClick={() => setCurrentPage(page => page - 1 || 1)}>
            <Icon name="arrow-simple" />
          </Button>
          {currentPage} / {totalPages}
          <Button
            simple
            disabled={currentPage === totalPages}
            onClick={() => setCurrentPage(page => (page + 1 < totalPages ? page + 1 : totalPages))}>
            <Icon name="arrow-simple" />
          </Button>
        </div>
      )}
      <Button onClick={() => null} className="editor-header__save-btn">
        Save document
      </Button>
    </StyledHeader>
  );

  return (
    <StyledEditorLayout
      content={content}
      sidebar={
        <Sidebar
          openShareAndSign={() => setOpenShareAndSign(true)}
          openSaveAs={() => setOpenSaveAs(true)}
        />
      }
      header={header}
    />
  );
};

export default () => (
  <PDFEditorContextWrapper>
    <PDFEditor />
  </PDFEditorContextWrapper>
);

const StyledEditorLayout = styled(EditorLayout)`
  overflow: hidden;

  header {
    justify-content: center;

    .logo-container {
      width: 205px;
    }

    .document-navigation {
      margin: auto;

      .button {
        margin: 0 10px;
        padding: 3px;

        &:nth-of-type(2) .icon {
          transform: rotate(180deg);
        }
      }
    }
  }

  .content {
    height: 100%;
    overflow: auto;
  }
`;

const StyledContent = styled.div`
  display: flex;
  justify-content: center;
  .share-and-sign {
    .modal-content {
      min-width: 600px;
    }
  }
`;

const StyledHeader = styled.div`
  margin: 0 16px;
  display: flex;
  flex-grow: 1;
  align-items: center;
  .editor-header {
    &__name {
      display: inline-flex;
      font-size: 12px;
      line-height: 16px;
    }
  }
`;
