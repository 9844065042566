import { fetchWithToken } from 'utils/fetch';
import { PDFEditorDocument } from './types';

export async function getPdfDocument(id: string): Promise<PDFEditorDocument> {
  const resposnse = await fetchWithToken(`/something/${id}`);
  const data = await resposnse.json();
  return data;
}

export async function savePdfDocument(id: string, documentChanges: any): Promise<void> {
  console.log('TODO');
}

export async function savePdfDocumentAs(documentChanges: any): Promise<void> {
  console.log('TODO');
}
