/* eslint-disable import/no-named-as-default */
import notify from 'notify';
import React, { useEffect, useReducer, useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { tryGetFirstError } from 'utils/requests';
import { getPdfDocument } from './api';
import editorReducer, { EditorReducerState, EditorReducerAction } from './editorReducer';
import { PDFEditorDocument } from './types';

interface PDFEditorContextValues {
  totalPages: number | undefined;
  setTotalPages: React.Dispatch<React.SetStateAction<number | undefined>>;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  document?: PDFEditorDocument;
  documentId: string;
  editor: EditorReducerState;
  editorDispatch: React.Dispatch<EditorReducerAction>;
}

const PDFEditorContext = React.createContext<PDFEditorContextValues>({} as PDFEditorContextValues);

export const PDFEditorContextWrapper = ({ children }) => {
  const [documentId] = useQueryParam<string>('id');
  const [document, setDocument] = useState<PDFEditorDocument>();
  const [totalPages, setTotalPages] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [editor, editorDispatch] = useReducer(editorReducer, {
    isAddingNode: false,
    isDeletingNode: false,
    newNodeType: undefined,
    editableNodes: []
  });
  console.log(editor);

  useEffect(() => {
    if (!documentId) return;
    (async () => {
      try {
        const document = await getPdfDocument(documentId);
        setDocument(document);
      } catch (err) {
        notify(tryGetFirstError(err));
      }
    })();
  }, [documentId]);

  const contextValues: PDFEditorContextValues = {
    totalPages,
    setTotalPages,
    currentPage,
    setCurrentPage,
    editor,
    editorDispatch,
    document,
    documentId
  };

  return (
    <PDFEditorContext.Provider value={{ ...contextValues }}>{children}</PDFEditorContext.Provider>
  );
};

export default PDFEditorContext;
